<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!product">
      <span class="loader"></span>
    </span>
    <div v-if="product" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <product-view-global :product="product" />
        </tab-pane>

        <tab-pane title="gallery" id="2">
          <span slot="title">
            <i class="fas fa-images"></i>
            {{ $t("COMMON.GALLERY") }}
          </span>
          <product-view-gallery :product="product" />
        </tab-pane>

        <tab-pane title="supplier-products" id="3">
          <span slot="title">
            <i class="ni ni-circle-08" />
            {{ $t("COMMON.SUPPLIERS") }}
          </span>
          <product-view-supplier-products :product="product" />
        </tab-pane>

        <tab-pane
          title="files"
          id="998"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_FILES)"
        >
          <span slot="title">
            <i class="ni ni-folder-17"></i>
            {{ $t("COMMON.FILES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <list-file-component :object="product" />
          </div>
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <product-view-logs :product="product" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import ProductViewGlobal from "../partials/ProductViewGlobal.vue";
import ProductViewLogs from "../partials/ProductViewLogs.vue";
import ProductViewGallery from "../partials/ProductViewGallery.vue";
import ProductViewSupplierProducts from "../partials/ProductViewSupplierProducts.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    ProductViewGlobal,
    ProductViewGallery,
    ProductViewLogs,
    ListFileComponent,
    ProductViewSupplierProducts,
  },

  mixins: [requestErrorMixin],

  props: {
    productId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      product: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("products/get", this.productId);
        this.product = this.$store.getters["products/product"];
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
